import React from "react";
import * as MaterialIcons from "@mui/icons-material"; // Import all Material Icons
import { Box } from "@mui/material";

// Dynamically import SVGs as React components
const importSvg = (iconName) => {
    try {
        return require(`../icons/${iconName}.svg`).ReactComponent;
    } catch (error) {
        console.error(`Error loading SVG: ${iconName}`, error);
        return null;
    }
};

const IconLoader = ({ iconType, icon, color, size = "50%" }) => {
    // Dynamically load Material Symbol
    if (iconType === "symbol") {
        return (
            <span
                className="material-symbols-outlined"
                style={{
                    fontSize: size,
                    color: color,
                }}
            >
                {icon}
            </span>
        );
    }

    // Dynamically load Material Icon
    if (iconType === "material") {
        const MaterialIcon = MaterialIcons[icon];
        if (MaterialIcon) {
            return <MaterialIcon style={{ fontSize: size, color: color }} />;
        } else {
            console.error(`Material Icon "${icon}" not found.`);
            return null;
        }
    }

    // Dynamically load custom SVG
    if (iconType === "svg") {
        const SvgIcon = importSvg(icon);
        if (SvgIcon) {
            return <SvgIcon style={{ width: size, height: size, fill: color }} />;
        } else {
            return null;
        }
    }

    // Fallback if iconType is invalid
    console.error(`Invalid iconType "${iconType}"`);
    return null;
};

export default IconLoader;