import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GridContainer from "./components/GridContainer";
import ProjectsPage from "./components/ProjectsPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Grid Page */}
        <Route path="/" element={<GridContainer />} />

        {/* Projects Page */}
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
    </Router>
  );
}

export default App;