import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import Material Icon

function BackButton({ size = "48px" }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <IconButton
            onClick={handleClick}
            sx={{
                position: "fixed", // Fix the button to the bottom of the page
                bottom: "20px", // 20px from the bottom
                left: "50%", // Center horizontally
                transform: "translateX(-50%)", // Adjust for the button's width
                zIndex: 1000, // Ensure it stays on top
                fontSize: size, // Match the size of the card icon
                color: "#000000", // Black icon color for contrast
                backgroundColor: "#f5f5f5", // Light gray background color
                padding: "10px", // Add padding for a circular button
                borderRadius: "50%", // Make the button circular
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
                "&:hover": {
                    backgroundColor: "#e0e0e0", // Slightly darker gray on hover
                },
            }}
        >
            <ArrowBackIcon sx={{ fontSize: size }} /> {/* Material Icon */}
        </IconButton>
    );
}

export default BackButton;