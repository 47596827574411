import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import IconLoader from "./IconLoader";
import BackButton from "./BackButton"; // Import the BackButton component
import projectData from "../data/projectPage.json";
import cardsData from "../data/cards.json";

function ProjectsPage() {
    const [projects, setProjects] = useState([]);
    const [headerData, setHeaderData] = useState(null);

    useEffect(() => {
        // Load project data
        setProjects(projectData);

        // Find the card data for the "projects" page
        const projectCard = cardsData.find((card) => card.page === "projects");
        if (projectCard) {
            setHeaderData(projectCard);
        }
    }, []);

    // Show a loading state if headerData is not yet loaded
    if (!headerData) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Typography variant="h6">Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: headerData.color || "#ffffff", // Use the card color as the header background
                minHeight: "100vh",
                padding: "20px",
                position: "relative", // Ensure the BackButton is positioned correctly
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between", // Align items to opposite corners
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                {/* Left Section: Icon and Title */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {headerData.icon && (
                        <IconLoader
                            iconType={headerData.iconType}
                            icon={headerData.icon}
                            color="#ffffff"
                            size="48px"
                        />
                    )}
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        Projects
                    </Typography>
                </Box>
            </Box>

            {/* Projects Grid */}
            <Grid container spacing={2}>
                {projects.map((project) => (
                    <Grid item xs={12} sm={6} md={4} key={project.id}>
                        <Box
                            sx={{
                                backgroundColor: "#ffffff",
                                padding: "20px",
                                borderRadius: "8px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                textAlign: "center",
                                cursor: "pointer",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {project.name}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {/* Back Button */}
            <BackButton size="48px" /> {/* Back button at the bottom of the page */}
        </Box>
    );
}

export default ProjectsPage;