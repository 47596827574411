import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import IconLoader from "./IconLoader";

function Card({ card }) {
    const { iconType, icon, page, color } = card;
    const navigate = useNavigate();

    return (
        <Box
            onClick={() => navigate(`/${page}`)} // Navigate to the page when the card is clicked
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: color, // Set the background color dynamically
                borderRadius: 2,
                boxShadow: 3,
                cursor: "pointer",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                    transform: "scale(1.05)", // Add a hover effect for better UX
                },
            }}
        >
            <IconLoader iconType={iconType} icon={icon} color="#f5f5f5" size="5dvw" />
        </Box>
    );
}

export default Card;