import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Card from "./Card";
import cardsData from "../data/cards.json";

function GridContainer() {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        // Load card data from JSON
        setCards(cardsData);
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // Full viewport height
                width: "100vw", // Full viewport width
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    width: "66vw", // 66% of the viewport width
                    height: "auto", // Automatically adjust height based on content
                }}
            >
                {cards.map((card) => (
                    <Grid item xs={4} key={card.id}>
                        <Box sx={{ position: "relative", width: "100%", paddingTop: "100%" }}>
                            <Card card={card} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default GridContainer;